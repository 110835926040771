"use strict";

import Splide from "@splidejs/splide";

function _toConsumableArray(r) {
  return (
    _arrayWithoutHoles(r) ||
    _iterableToArray(r) ||
    _unsupportedIterableToArray(r) ||
    _nonIterableSpread()
  );
}

function _nonIterableSpread() {
  throw new TypeError(
    "Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."
  );
}

function _unsupportedIterableToArray(r, e) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, e);
    var t = Object.prototype.toString.call(r).slice(8, -1);
    return (
      "Object" === t && r.constructor && (t = r.constructor.name),
      "Map" === t || "Set" === t
        ? Array.from(r)
        : "Arguments" === t ||
          /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t)
        ? _arrayLikeToArray(r, e)
        : void 0
    );
  }
}

function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && Symbol.iterator in Object(r))
    return Array.from(r);
}

function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}

function _arrayLikeToArray(r, e) {
  (null == e || e > r.length) && (e = r.length);
  for (var t = 0, a = new Array(e); t < e; t++) a[t] = r[t];
  return a;
}

$(document).on("turbolinks:load", function () {
  var r = document.querySelector("[data-header]"),
    e = document.querySelector("[data-togle-menu]");
  e &&
    e.addEventListener("click", function () {
      r.classList.toggle("is-open");
    });
  e = document.querySelectorAll("[data-slider]");
  e &&
    e.forEach(function (r) {
      var e = r.dataset.slider;
      new Splide(r, {
        arrows: !1,
        gap: 20,
        perPage: 3,
        destroy: (e = e) <= 3,
        breakpoints: {
          750: { perPage: 1, destroy: e <= 1 },
          992: { perPage: 2, destroy: e <= 2 },
        },
      }).mount();
    });
  e = document.querySelector("[data-faq-list]");
  e &&
    _toConsumableArray(e.querySelectorAll("dt")).forEach(function (r) {
      return r.addEventListener("click", function () {
        r.classList.toggle("is-active"),
          r.nextElementSibling.classList.toggle("is-active");
      });
    });
});
